import React, { Component } from 'react';
import {ProductConsumer} from '../context';
import {Link} from 'react-router-dom';
import NavBarFr from './NavBarFr';


export default class Details extends Component {
    render() {
        return (
            <div>
                <NavBarFr/>
            
            <ProductConsumer>
                {value => {
                 const {id, img, infofr, titlefr}  =
                 value.detailProduct;
                 return(
                     <div className="container py-5">
                         {/* title */}
                         <div className="row">
                         <div className="col-10 mx-auto text-center text-slanted text-blue my-5">
                             <h1>{titlefr}</h1>
                         </div>
                         </div>
                         {/*End title */}
                         {/*product info */}
                         <div className="col-10 mx-auto col-md-6 my-3 text-capitalize">
                             <img src={img} className="img-fluid" alt="produit"/>
                         </div>
                         {/* product text */}
                         <div className="col-10 mx-auto col-md-6 my-3 text-capitalize">
                             <h2> Article : {titlefr}</h2>
                             <p className="text-capitalized font-weight-bold mt-3 mb-0">
                                 L'information sur ce produit:

                             </p>
                             <p className="text-muted lead"> {infofr}</p>
                         </div>

                     </div>
                 )
                }}
            </ProductConsumer>
            </div>
        );
    }
}
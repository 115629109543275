import React, { Component } from 'react';
import NavBar from './NavBar';

export default class Contact extends Component {
    render() {
        return (
            <div>
                <NavBar/>
                
                <h3 className="text-uppercase text-center py-5 text-primary">Contact Us</h3>
                <h4 className="text-center">
                Tel: 514-384-1119<br/>
                Fax: 514-384-5304<br/>
                Email: goodlak@bellnet.ca 
                </h4>

                <h4 className="text-center pt-5 pb-2 text-primary">
                    Visit Us At:
                </h4>

                <h4 className="text-center">
                9600 Meilleur Road<br/>
                Suite Number: 840<br/>
                Montreal, Quebec<br/>
                H2N-2E3
                </h4>
            </div>
        )
    }
}
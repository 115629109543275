import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export default class DefaultFr extends Component {
    render() {
        return (
            <div>
            <h3 className="text-uppercase text-center py-5 text-danger"> Error: Page Not Found</h3>

            <Link to="/">
            <h4 className="text-center text-u"> <u>Return to home page</u></h4>
            </Link>
            
            </div>
        )
    }
}
import React, { Component } from 'react';
import hoodies from '../pics/110 Clr-.JPG'
import sweats from '../pics/278 Clrs.jpg'
import zipups from '../pics/220-color-.jpg'
import NavBar from './NavBar';

export default class Landing extends Component {
    render() {
        return (
            <div className="text-center">
                <NavBar/>
                <h3 className="text-center py-5 text-primary">Good-Lak Textiles</h3>
                <img src={hoodies} width="280" height="350" className="py-5 px-2 align-center" />
                <img src={sweats} width="250" height="350" className="py-5 px-2" />
                <img src={zipups} width="250" height="350" className="py-5 px-2" />
                <p className="text-center">
                We are one of the leading importers and stockist of sportswear, Including hoodies, jogging sets, polos, t-shirts.
               </p>
               <p className="text-center">
                We also carry cotton tote bags, laundry bags, shoe bags, aprons. 
                </p>
            </div>
        )
    }
}
import React, { Component } from 'react';
import Product from './Product';
import Title from './Title';
import {ProductConsumer} from '../context';
import NavBar from './NavBar';

export default class ProductList extends Component {
    
    render() {
        return (
            <React.Fragment>
                <NavBar/>
                <div className="py-5">
                <div className="container">
                {/* <Title name="Our" title="Products" /> */}
                <h3 className="text-center text-primary">
                    Our Products
                </h3>

                <div className="row">
                <ProductConsumer>
                    {value=>{
                      return value.products.map( product=>{
                          return <Product key={product.id}
                           product={product} />;
                      })
                    }}
                </ProductConsumer>

                </div>
                </div>    
                </div>

            </React.Fragment>
            //<h3><Product /></h3>  
            
        )
    }
}
import React from 'react';
import {Switch,Route} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import {IntlProvider} from "react-intl";
import Navbar from './components/NavBar';
import ProductList from './components/ProductList';
import Product from './components/Product';
import Default from './components/Default';
import Details from './components/Details';
import Landing from './components/Landing';
import Contact from './components/Contact';


import NavbarFr from './components/NavBarFr';
import ProductListFr from './components/ProductListFr';
import ProductFr from './components/ProductFr';
import DefaultFr from './components/DefaultFr';
import DetailsFr from './components/DetailsFr';
import LandingFr from './components/LandingFr';
import ContactFr from './components/ContactFr';


function App() {
  return (
    <IntlProvider locale="fr" messages={{
      // 'nav.product': "Produits"
    }}>
    <React.Fragment>
      {/* <Navbar/> */}
      <Switch>
        <Route exact path="/" component={Landing}></Route>
        <Route path="/products" component={ProductList}></Route>
        <Route path="/details" component={Details}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route exact path="/fr" component={LandingFr}></Route>
        <Route path="/fr/products" component={ProductListFr}></Route>
        <Route path="/fr/details"component={DetailsFr}></Route>
        <Route path="/fr/contact" component={ContactFr}></Route>
        <Route component={Default} />

      </Switch>

      {/* <NavbarFr/> */}
    </React.Fragment>
    </IntlProvider>
  );
}

export default App;

import React, { Component } from 'react';
import NavBarFr from './NavBarFr';

export default class Contact extends Component {
    render() {
        return (
            <div>
                <NavBarFr/>
                
                <h3 className="text-uppercase text-center py-5 text-primary">Contactez-Nous</h3>
                <h4 className="text-center">
                Tel: 514-384-1119<br/>
                Fax: 514-384-5304<br/>
                Courriel: goodlak@bellnet.ca 
                </h4>

                <h4 className="text-center pt-5 pb-2 text-primary">
                    Visitez-Nous à:
                </h4>

                <h4 className="text-center">
                9600 Rue Meilleur<br/>
                Numéro de suite: 840<br/>
                Montréal, Québec<br/>
                H2N-2E3
                </h4>
            </div>
        )
    }
}
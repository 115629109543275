

export const storeProducts = [
    {
        id: 1,
        title: "Men's Pull Over Hoodies",
        titlefr: "Chandail à capuchon pour hommes",
        img: 'img/110 ANTHRA Color.jpg',
        infofr:`
        Chandail à capuchon pour hommes
        NOIR, BLEU MARINE, ROUGE, GRIS, BLANC, GRIS-CHARBON, VIOLET, TURQUOISE
        Taille: P | M | G | TG | TTG | TTTG | 4TG
        `,
        info: `
        Men's Fleece Hooded Sweatshirt.
        BLACK, NAVY, RED, GREY, WHITE , CHARCOLE, PURPLE, TURQUOIS
        Sizes: S | M | L | XL | XXL | XXXL | 4XL 
        `,
    },
    
    {
        id: 2,
        title: "Men's Zip Hoodies",
        titlefr: "Chandail à capuchon avec zip pour hommes.",
        img: "img/220 Burgandy.jpg",
        infofr: `
        Chandail à capuchon avec zip pour hommes.
        80% coton - 20% polyester.
        Disponible en NOIR, BLEU MARINE, GRIS, BLANC, ROUGE, MARRON, ROYAL, GRIS FONCÉ, CHARCOLE, OLIVE, BOURGOGNE.
        Taille: P | M | G | TG | TTG | TTTG | 4TG
        `,
        info: `
        Men's Fleece Zip Hoodie.
        80% Cotton - 20% Polyester.
        Available in BLACK, NAVY, GREY, WHITE, RED,  BROWN, ROYAL, DARK GREY, CHARCOLE, OLIVE, BURGUNDY
        Sizes: S | M | L | XL | XXL | XXXL | 4XL 
        `,
    },

    {
        id: 3,
        title: "Men's Crewneck Sweatshirt",
        titlefr: "Molleton à col arrondi pour hommes.",
        img: "img/278 Anthra.JPG",
        infofr:  `
        Molleton à col arrondi pour hommes.
        80% coton - 20% polyester.
        Taille: P | M | G | TG | TTG | TTTG 
        `,

        info:  `
        Men's Fleece Crew Neck.
        80% Cotton - 20% Polyester.
        Sizes: S | M | L | XL | XXL | XXXL  
        `,
    },

    {
        id: 4,
        title: "Contrast Tote Bags",
        titlefr: "Sacs fourre-tout contrastés",
        img: "img/021 COLORS-.jpg",
        infofr: `
        Sacs en toile 100% coton naturels avec base colorée et poignées colorées. 
        Dimensions: 46 x 46 + 15 cm Soufflet de fond.
        
        Couleurs:
        1.  Naturel avec base rouge + longues poignées rouges
        2.  Naturel avec base bourguignonne + longues poignées bordeaux
        3.  Naturel avec base verte + verte longues poignées bordeaux
        4.  Naturel avec base noir + noir longues poignées bordeaux
        5.  Naturel avec base bleu marine + bleu marine longues poignées bordeaux
        6.  Naturel avec base violet + violet longues poignées bordeaux`,
        info: `
        100 % Cotton Canvas Natural Bags With Colored Base & Long Handles. 
        Sizes: 46 x 46 + 15 cm Bottom Gusset. 
        
        Colors:
        1.  Natural  With Red Base + Red Long Handles  
        2.  Natural  With Burgandy Base + Burgandy Long Handles 
        3.  Natural  With Green Base + Green Long Handles
        4.  Natural  With Black Base + Black Long Handles
        5.  Natural  With Navy Base + Navy Long Handles
        6.  Natural  With Purple Base + Purple Long Handles`,
    },

    {
        id: 5,
        title: "Light Apron",
        titlefr: "Tablier léger",
        img: "img/Apron WR-504.JPG",
        infofr:  `
        Article: Tablier blanc avec passepoil rouge
        Taille: 59cm X 95cm
        Tissu: 100% coton
        `,
        info:  `
        Item: WHITE APRON WITH RED PIPING
        Size: 59cm X 95cm
        Fabric: 100% Cotton
        `,
    },

    {
        id: 6,
        title: "Dark Apron",
        titlefr: "Tablier foncé",
        img: "img/DSC09618.JPG",
        infofr:  `
        Article: Tablier noir
        Taille: 60cm X 84cm
        Tissu: 100% coton
        `,
        info:  `
        Item: Black APRON 
        Size: 60cm X 84cm
        Fabric: 100% Cotton Twill
        `,
    },

    {
        id: 7,
        title: "Ladies Pull Over Hoodie",
        titlefr: "Chandail à capuchon pour femmes",
        img: "img/111 - KELLY GREEN.JPG",
        infofr:  `
        Article: Chandail à capuchon pour femmes avec poche kangourou
        Tissu: 80% coton - 20% polyester
        Couleur: Noir,  Blue Marine, Rose pâle, Blanc , Gris , Rouge ,  Violet,  Fuschia,  Vert Kelly, Olive,  Turquoise,  Anthra-Gris 
        Taille: P | M | G | TG                 
        `,
        info:  `
        Item: Ladies  Hoodies with Kangroo Pocket 
        Fabric: 80% Cotton - 20% Polyester
        Color: Black,  Navy,  Lt. Pink, White , H-grey , Red ,  Purple,  Fuschia,  K.Green, Olive,  Turquish,  Anthra 
        Sizes: S | M | L | XL                 
        `,
    },

    {
        id: 8,
        title: "Ladies Zip Up Fleece Hoodie",
        titlefr: "Chandail à capuchon avec zip pour femmes",
        img: "img/image002.jpg",
        infofr:  `
        Article: Chandail à capuchon avec zip et poche kangourou pour femmes           
        Tissu: 80/20 coton/polyester - Lourd 
        Couleur: Violet, Fushia, Vert Kelly, Olive, Bleu Pâle, Noir, Bleu Marine, Rouge, Gris, Blanc, Brun, Turqouise, Turqoise Pâle, Bourgogne
        Taille: P | M | G | TG           
        `,
        info:  `
        Item: Ladies Full Zip Fleece Hoodies with Kangroo Pocket           
        Fabric: 80/20 Cotton/Polyester - Heavy 
        Color: Purple, Fushia-Pink, Kally-Green, Olive, Light-Blue, Black, Navy, Red, Grey, White, Brown, Turqish, Light Turqoise, Burgandy
        Sizes: S | M | L | XL           
       `,
    },

    {
        id: 9,
        title: "Men's T-Shirt",
        titlefr: "Chandail T-Shirt pour hommes",
        img: "img/112 Royal.JPG",
        infofr:  `
        Article: Chandail T-Shirt pour hommes  
        Fabric: 60% coton - 40% polyester
        Colors: Noir, Bleu Marine, Blanc, Rouge, Gris, Bleu Royal                        
        Taille: P | M | G | TG | TTG               
        `,
        info:  `
        Item: Mens T-shirts Heavy  
        Fabric: 60% Cotton - 40% Polyester - Heavy
        Colors: Black, Navy, White, Red, H-Grey, Royal Blue                        
        Sizes: S | M | L | XL | XXL                
        `,
    },


 
];

export const detailProduct = {
    id: 1,
    title: "Purple Hoodie",
    img: "img/110 - Purple.jpg",
    info: "Sample Product"
}
import React, { Component } from 'react';
import hoodies from '../pics/110 Clr-.JPG'
import sweats from '../pics/278 Clrs.jpg'
import zipups from '../pics/220-color-.jpg'
import NavBarFr from './NavBarFr';

export default class LandingFr extends Component {
    render() {
        return (

            <div className="text-center">
                <NavBarFr/>
                <h3 className="text-center py-5 text-primary">Good-Lak Textiles</h3>
                <img src={hoodies} width="280" height="350" className="py-5 px-2 align-center" />
                <img src={sweats} width="250" height="350" className="py-5 px-2" />
                <img src={zipups} width="250" height="350" className="py-5 px-2" />
                <p className="text-center">
                {/* FRENCH: We are one of the leading importers and stockist of fleece sportswear, Including hoodies, jogging sets, t-shirts. */}
                Nous sommes un des meilleurs importateurs de vêtements de sport, comme des chandails à capuchon, des ensembles de jogging, des chandails/T-shirts. 
               </p>
               <p className="text-center">
                {/* FRENCH: We also carry cotton tote bags, laundry bags, shoe bags and, aprons. */}
                Nous avons aussi des sacs fourre-tout, des sacs à linge, des sacs à chaussures et, des tabliers. 
                </p>
            </div>
        )
    }
}
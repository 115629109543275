import React, { Component } from 'react';
import {Link} from 'react-router-dom';

export default class NavBarFr extends Component {
    render() {
        return (
           <nav className="navbar navbar-expand-sm bg-dark navbar-white px-sm-5">
            <Link to='/fr' className="nav-link">
            Good-Lak Textiles
            </Link>
            <Link to='/fr/products' className="nav-link">
            Produits
            </Link>
            <Link to='/fr/contact' className="nav-link">
                Contactez-Nous
            </Link>
            <Link to='/' className="nav-link text-light">
                Ang
            </Link>
            
            <Link to='/fr' className="nav-link text-light">
                Fr
            </Link>
            
            
            


           </nav>
        )
    }
}
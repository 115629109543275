import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from "react-intl";

export default class NavBar extends Component {
    render() {
        return (
           <nav className="navbar navbar-expand-sm bg-dark navbar-white px-sm-5">
            <Link to='/' className="nav-link">
            Good-Lak Textiles
            </Link>
            <Link to='/products' className="nav-link">
            <FormattedMessage 
            id="nav.product"
            defaultMessage="Product"
            />
            </Link>
            <Link to='/contact' className="nav-link">
                Contact Us
            </Link>

            <Link exact to='/' className="nav-link text-light">
                En
            </Link>   

            <Link to='/fr' className="nav-link text-light">
                Fr
            </Link>

           </nav>

        )
    }
}
import React, { Component } from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { ProductConsumer } from '../context';
import PropTypes from 'prop-types';




export default class ProductFr extends Component {
    render() {
        const {id, titlefr, img} = this.props.productfr;
        return (
            
            <ProductWrapper className="col-9 mx-auto col-md-6 col-lg-4 my-3">
              <div className="card">

                  <ProductConsumer>
                      {value=> (<div className="img-container p-5" onClick={()=>
                      value.handleDetail(id)
                        }>
                  
                    
                  <Link to="/fr/details">

                      {/* <img src={zipups} alt="image"/> */}

                      <img src={img} alt="produit" className="card-img-top"/>

                  </Link>

              </div>)}
                </ProductConsumer>

                <div className="card-footer d-flex justify-content-between">
                    <p className="align-center mb-0">
                        {titlefr}
                    </p>
                </div>
              </div>
            </ProductWrapper>
        )
    }
}
/*
ProductFr.propTypes = {
    productfr: PropTypes.shape({
        id:PropTypes.number,
        imgfr: PropTypes.string,
        titlefr: PropTypes.string,
        infofr: PropTypes.string
    }).isRequired
}
*/
ProductFr.propTypes = {
    productfr: PropTypes.shape({
        id: PropTypes.number,
        img: PropTypes.string,
        title: PropTypes.string,
        titlefr: PropTypes.string,
        info: PropTypes.string,
        infofr: PropTypes.string
    }).isRequired
}

const ProductWrapper = styled.div` 
.card {
    border-color: transparent
    transistion:all 1s linear;
}
.card-footer{
    background:transparent;
    border-top: transparent;
}
&:hover{
    .card{
        border:0.04rem solid rgba(0,0,0,0.2);
        box-shadow:2px 2px 5px 0px rgba(0,0,0,0.2)
    }
    .card-footer{
        background: rgba(247,247,247);
    }
}
.img-container{
    position:relative;
    overflow: hidden;
}
.img-card-top{
    transition: all 1s linear;
}
.image-container:hover {
    transform:scale(1.4);
}

`;

import React, { Component } from 'react';
import ProductFr from './ProductFr';
import Title from './Title';
import {ProductConsumer} from '../context';
import NavBarFr from './NavBarFr';

export default class ProductListFr extends Component {
    
    render() {
        return (
            <React.Fragment>
                <NavBarFr/>
                <div className="py-5">
                <div className="container">
                {/* <Title name="Our" title="Products" /> */}
                <h3 className="text-center text-primary">
                    Nos Produits
                </h3>

                <div className="row">
                <ProductConsumer>
                    {value=>{
                      return value.products.map( productfr=>{
                          return <ProductFr key={productfr.id}
                           productfr={productfr} />;
                      })
                    }}
                </ProductConsumer>

                </div>
                </div>    
                </div>

            </React.Fragment>
            //<h3><Product /></h3>  
            
        )
    }
}